<template>
  <v-container class="d-flex justify-center" fluid>
    <ticket-content-modal
      ref="ticketContentModalRef"
      :selectedData="selectedData"
      :isUser="true"
      @GetList="GetList"
    />
    <ticket-new-modal ref="ticketNewModalRef" @GetList="GetList" />
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card class="moholand-shadow rounded-lg">
          <v-card-title>
            تیکت و پشتیبانی
          </v-card-title>

          <div class="d-flex flex-wrap pa-4">
            <v-btn
              dark
              large
              class="rounded-lg mx-2 moholand-shadow my-auto"
              color="orange darken-2"
              @click="onDetailsClick"
            >
              افزودن تیکت جدید
            </v-btn>
            <search-bar @onSubmit="onSearchSubmit" />
          </div>

          <MyDataTable
            :headers="headers"
            :items="searchData"
            :loading="loading"
            item-key="id"
            :pagesLength="pagesLength"
            @onPageChanged="GetList"
            :isRemoveEnabled="false"
            :is-edit-enabled="false"
          >
            <template #middleButtonTag="{ row }">
              <v-btn
                class="mx-1"
                dark
                small
                color="primary"
                @click="onShowTicket(row)"
              >
                مشاهده
              </v-btn>
            </template>
            <template #contentTag="{ row }">
              <td>{{ row.title }}</td>
              <td>
                <v-chip
                  v-if="row.status == 1"
                  color="success"
                  dark
                  class="rounded"
                  small
                >
                  دارای پاسخ
                </v-chip>
                <v-chip v-else color="red" dark class="rounded" small>
                  در حال بررسی
                </v-chip>
              </td>
            </template>
          </MyDataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyDataTable from "../../components/MyDataTable.vue";
import TicketContentModal from "../../components/Panel/TicketContentModal.vue";
import TicketNewModal from "../../components/Panel/TicketNewModal.vue";
import SearchBar from "../../components/SearchBar.vue";
import MyAxios from "@/constants/MyAxios";
export default {
  components: {
    SearchBar,
    MyDataTable,
    TicketContentModal,
    TicketNewModal,
  },
  mounted() {
    this.GetList();
  },
  methods: {
    onSearchSubmit(name = "") {
      if (name.length) {
        this.searchData = this.searchData.filter(
          (val) =>
            val.title.includes(name) ||
            (val.parent && val.parent.title && val.parent.title.includes(name))
        );
      } else {
        this.searchData = this.items;
      }
    },
    onDetailsClick() {
      this.$refs.ticketNewModalRef.showModal();
    },
    onShowTicket(item) {
      this.selectedData = item;
      this.$refs.ticketContentModalRef.showModal();
    },
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/tickets/index", {
        params: {
          page: this.$route.query?.page || 1,
          ...this.pathParams,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.searchData = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: false,
      selectedData: {},
      conditions: {
        user_id: this.$store.state.userData?.id || 0,
      },
      page: 1,
      pagesLength: 1,
      path: "/tickets/index",
      pathParams: {
        with: ["user", "parent"],
        conditions: {
          user_id: this.$store.state?.userData?.id,
          parent_id: null,
        },
      },
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "title",
        },
        { text: "وضعیت", value: "child_id" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
      items: [],
      searchData: [],
    };
  },
};
</script>

<style></style>
